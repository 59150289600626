import React from 'react'
import { graphql } from 'gatsby'
import Footer from '../components/Footer/Footer'
import HeaderProvider from '../components/Header/HeaderProvider'
import Section from '../components/Section/Section'
import ButtonOutlinedGatsbyLink from '../components/ButtonOutlinedGatsbyLink/ButtonOutlinedGatsbyLink'
import PageHeader from '../components/PageHeader/PageHeader'

// import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
// Sections for this page

const Home = props => {
  const { data } = props

  return (
    <div>
      <HeaderProvider
        localURLS={[
          { 'locale': 'de', 'value': '' },
          { 'locale': 'en', 'value': '' },
        ]}
        actLang={'en'}
        SEOtitle={'404'}
        SEOdescription={'404 - Perfect Wetsuit'}
        SEOpathname={''}
        logoAsH1
      />
      <PageHeader
        image={data.datoCmsHome.headerImage.fluid}
        alt={data.datoCmsHome.headerImage.alt}
        title={'404'}
        subtitle={'Page not Found. Sorry but there is no such Page, to go back to Home please click on the Button below'}
      />
      <Section paddingTop={0}>
        <div style={{ marginTop: -30 }}/>
        <ButtonOutlinedGatsbyLink link={''} title={'Go back to Home'}/>
      </Section>
      <Footer
        actLang={'en'}
      />
    </div>
  )
}

export default Home

export const query = graphql`
    query{
        datoCmsHome {
            title
            pageSubtitle
            headerImage{
                alt
                fluid(maxWidth:1800){
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }

        }

    }
`